<template>
    <div>
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/headers/${header_image}`" class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1 is-size-2-mobile">
                        {{title}}
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="content is-medium">
                    <p class="subtitle is-4" v-html="subtitle"></p>
                    <div class="buttons">
                        <router-link class="button is-medium is-primary" :to="{name: 'shop'}">Hier geht's zum Domino-Shop</router-link>
                        <router-link class="button is-medium is-success is-outlined" :to="{name: 'home'}">Hier geht's zur Startseite</router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    export default {
        metaInfo: {
            title: 'Info'
        },
        data: function () {
            return {
                header_image: 'domino_404.png'
            }
        },
        computed: {
            title() {
                switch (this.page_type) {
                    case 'rentSubmitted':
                        return "Vermietungsanfrage empfangen"
                    case 'courseSubmitted':
                        return "Kursangebotsanfrage empfangen"
                    default:
                        return 'Seite nicht gefunden.'
                }
            },
            subtitle() {
                switch (this.page_type) {
                    case 'rentSubmitted':
                        return "Vielen Dank für Ihre Vermietungsanfrage. Wir werden uns schnellstmöglich darum kümmern und mit Ihnen Kontakt aufnehmen.<br><br><strong>Ihr Swiss Domino Effect - Team</strong>"
                    case 'courseSubmitted':
                        return "Vielen Dank für Ihre Kursanfrage. Wir werden uns schnellstmöglich darum kümmern und mit Ihnen Kontakt aufnehmen.<br><br><strong>Ihr Swiss Domino Effect - Team</strong>"
                    default:
                        return 'Leider konnte diese Seite nicht gefunden werden.'
                }
            },
            is_error() {
                return !this.page_type
            },
            page_type() {
                return this.$route.params.type
            }
        }
    }
</script>